var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ui-parent-section" },
    [
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("div", [_vm._v("Селект (Основной)")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c(
                "el-select",
                {
                  staticClass: "custom-component",
                  attrs: { placeholder: "Селект", clearable: "" },
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("div", [_vm._v("Селект (Вспомогательный)")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c(
                "el-select",
                {
                  staticClass: "secondary",
                  attrs: { placeholder: "Селект", clearable: "" },
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("div", [_vm._v("Селект мульти (Вспомогательный)")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c(
                "el-select",
                {
                  staticClass: "secondary",
                  attrs: {
                    placeholder: "Селект",
                    clearable: "",
                    multiple: "",
                    "collapse-tags": "",
                  },
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("div", [_vm._v("Текстовое поле (Основное)")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c("el-input", {
                attrs: {
                  size: "small",
                  placeholder: "Please input",
                  clearable: "",
                },
                model: {
                  value: _vm.input,
                  callback: function ($$v) {
                    _vm.input = $$v
                  },
                  expression: "input",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "UISection",
        {
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [_c("div", [_vm._v("Текстовое поле (Вспомогательное)")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            [
              _c("el-input", {
                staticClass: "secondary",
                attrs: { placeholder: "Please input", clearable: "" },
                model: {
                  value: _vm.input,
                  callback: function ($$v) {
                    _vm.input = $$v
                  },
                  expression: "input",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }