<template>
  <div class="ui-parent-section">
    <UISection>
      <template #title>
        <div>Селект (Основной)</div>
      </template>

      <div>
        <el-select
          v-model="value"
          class="custom-component"
          placeholder="Селект"
          clearable
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </UISection>

    <UISection>
      <template #title>
        <div>Селект (Вспомогательный)</div>
      </template>

      <div>
        <el-select
          v-model="value"
          class="secondary"
          placeholder="Селект"
          clearable
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </UISection>

    <UISection>
      <template #title>
        <div>Селект мульти (Вспомогательный)</div>
      </template>

      <div>
        <el-select
          v-model="value"
          class="secondary"
          placeholder="Селект"
          clearable
          multiple
          collapse-tags
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </UISection>

    <UISection>
      <template #title>
        <div>Текстовое поле (Основное)</div>
      </template>

      <div>
        <el-input
          v-model="input"
          size="small"
          placeholder="Please input"
          clearable
        />
      </div>
    </UISection>

    <UISection>
      <template #title>
        <div>Текстовое поле (Вспомогательное)</div>
      </template>

      <div>
        <el-input
          v-model="input"
          class="secondary"
          placeholder="Please input"
          clearable
        />
      </div>
    </UISection>
  </div>
</template>

<script>
import UISection from '@/views/ui/components/UISection'
export default {
  name: 'Inputs',
  components: { UISection },
  data() {
    return {
      options: [
        { value: 'Option1', label: 'Option1' },
        { value: 'Option2', label: 'Option2' },
        { value: 'Option3', label: 'Option3' },
        { value: 'Option4', label: 'Option4' },
        { value: 'Option5', label: 'Option5' },
      ],
      value: '',
      input: '',
    }
  },
}
</script>
